import { CodeToText } from 'element-china-area-data'

export function spellAdminCode(province, city, area) {
	return parseInt(
		String(province).padStart(2, 0) +
		String(city).padStart(2, 0) +
		String(area).padStart(2, 0)
	)
}

export function spellAdminText(province, city, area) {
	const l1 = province * 10000
	const l2 = l1 + city * 100
	const l3 = l2 + area
	return CodeToText[l1] + ' ' + CodeToText[l2] + ' ' + CodeToText[l3];
}


export default {
	spellAdminCode
}